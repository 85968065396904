import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import "./CookieBanner.css"; // Importando o CSS separado

export default function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <CookieConsent
      location="bottom"
      buttonText="Aceitar Cookies"
      declineButtonText="Recusar"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      cookieName="cookieConsent"
      expires={180}
      overlay
      containerClasses="cookie-banner"
      buttonClasses="cookie-button accept"
      declineButtonClasses="cookie-button decline"
    >
      <p>
        Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa{" "}
        <a href="https://lup.com.br/politica-privacidade/app" className="cookie-link" target="_blank">
          Política de Privacidade
        </a>.
      </p>
    </CookieConsent>
  );
}
