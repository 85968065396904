import React, { useEffect } from "react";
import Banner from "./Banner";
import Filtro from "./Filtro";
import Galeria from "./Galeria";
import Footer from "../../components/Footer/Footer";
import CookieBanner from "../../components/cookies/CookieBanner"; // Importando o banner
import "./css/home.css";

export default function Home() {
  useEffect(() => {
    smoothScroll();
  }, []);

  const smoothScroll = () => {
    document.querySelector("html").style = "scroll-behavior: smooth;";
  };

  return (
    <>
      <Banner />
      <Filtro />
      <Galeria />
      <Footer />
      <CookieBanner /> 
    </>
  );
}
